import { API, GraphQLQuery, GraphQLSubscription } from "@aws-amplify/api";
import { useEffect, useState } from "react";
import {
  Device,
  ListDevicesQuery,
  ListDevicesQueryVariables,
  OnCreateDeviceSubscription,
  OnCreateDeviceSubscriptionVariables,
  OnUpdateDeviceSubscription,
  OnUpdateDeviceSubscriptionVariables,
} from "../API";
import * as queries from "../graphql/queries";
import * as subscriptions from "../graphql/subscriptions";

const useDevices = (clientId: string) => {
  const [devices, setDevices] = useState<Device[]>([]);

  const fetchDevices = async () => {
    const variables: ListDevicesQueryVariables = {
      clientID: clientId,
      limit: 1000,
    };
    try {
      const data = await API.graphql<GraphQLQuery<ListDevicesQuery>>({
        query: queries.listDevices,
        variables,
      });

      const users = data.data?.listDevices?.items as Device[];
      setDevices(users);
    } catch (error) {
      console.log("error fetching users >>", error);
    }
  };

  const subscribeDeviceUpdate = () => {
    const callback = (updatedDevice: Device) => {
      //Important to use prev State, because devices might be not fetched yet (async code in)
      setDevices((prev) => {
        const updatedArray = prev.map((device) => {
          if (device.name === updatedDevice.name) {
            return {
              ...updatedDevice,
            };
          }
          return device;
        });
        return updatedArray;
      });
    };

    const variables: OnUpdateDeviceSubscriptionVariables = {
      filter: {
        // Only receive Device messages where the "clientId" field is clientId
        clientID: {
          eq: clientId,
        },
      },
    };

    const subscription = API.graphql<
      GraphQLSubscription<OnUpdateDeviceSubscription>
    >({
      query: subscriptions.onUpdateDevice,
      variables: variables,
    }).subscribe({
      next: ({ value }) => callback(value.data?.onUpdateDevice as Device),
      error: (error) => console.error(error),
    });

    return subscription;
  };

  const subscribeDeviceCreate = () => {
    const callback = (createdDevice: Device) => {
      setDevices((prev) => [...prev, { ...createdDevice }]);
    };

    const variables: OnCreateDeviceSubscriptionVariables = {
      filter: {
        clientID: {
          eq: clientId,
        },
      },
    };

    const subscription = API.graphql<
      GraphQLSubscription<OnCreateDeviceSubscription>
    >({
      query: subscriptions.onCreateDevice,
      variables: variables,
    }).subscribe({
      next: ({ value }) => callback(value.data?.onCreateDevice as Device),
      error: (error) => console.error(error),
    });

    return subscription;
  };

  useEffect(() => {
    fetchDevices();

    const subscriptionDevicesUpdate = subscribeDeviceUpdate();
    const subscriptionDevicesCreate = subscribeDeviceCreate();

    return () => {
      subscriptionDevicesUpdate.unsubscribe();
      subscriptionDevicesCreate.unsubscribe();
    };
  }, []);

  return {
    devices: devices,
    setDevices: setDevices,
  };
};

export default useDevices;
