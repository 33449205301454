import { FC } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import * as Sentry from "@sentry/react";
import ErrorPage from "../../scenes/App/ErrorPage";
import Profile from "../../scenes/ClientProfile/Profile";
import Events from "../../scenes/Events/Events";
import SharedLayout from "../../scenes/global/SharedLayout";
import Home from "../../scenes/Home/Home";
import UserEdit from "../../scenes/Users/UserEdit";
import Users from "../../scenes/Users/Users";
import WorkspaceEdit from "../../scenes/Workspaces/WorkspaceEdit";
import Workspaces from "../../scenes/Workspaces/Workspaces";
import Popups from "../../scenes/Popups/Popups";
import Reports from "../../scenes/Reports/Reports";

/**
 * @summary - App Router
 */

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <SharedLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/reports",
        element: <Reports />,
      },
      {
        path: "/workspaces",
        children: [
          {
            index: true,
            element: <Workspaces />,
          },
          {
            path: ":id",
            element: <WorkspaceEdit />,
          },
        ],
      },
      {
        path: "/users",
        children: [
          {
            index: true,
            element: <Users />,
          },
          {
            path: ":id",
            element: <UserEdit />,
          },
        ],
      },
      {
        path: "/events",
        children: [
          {
            index: true,
            element: <Events />,
          },
          {
            path: "userName/:value",
            element: <Events fieldType="userName" />,
          },
          {
            path: "deviceName/:value",
            element: <Events fieldType="deviceName" />,
          },
        ],
      },
      {
        path: "/popups",
        element: <Popups />,
      },
    ],
  },
]);

const AppPagesRoutes: FC = () => {
  return <RouterProvider router={router} />;
};

export default AppPagesRoutes;
