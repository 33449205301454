/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateClient = /* GraphQL */ `
  subscription OnCreateClient($filter: ModelSubscriptionClientFilterInput) {
    onCreateClient(filter: $filter) {
      id
      legalName
      devices {
        items {
          clientID
          name
          location
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      users {
        items {
          clientID
          name
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateClient = /* GraphQL */ `
  subscription OnUpdateClient($filter: ModelSubscriptionClientFilterInput) {
    onUpdateClient(filter: $filter) {
      id
      legalName
      devices {
        items {
          clientID
          name
          location
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      users {
        items {
          clientID
          name
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteClient = /* GraphQL */ `
  subscription OnDeleteClient($filter: ModelSubscriptionClientFilterInput) {
    onDeleteClient(filter: $filter) {
      id
      legalName
      devices {
        items {
          clientID
          name
          location
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      users {
        items {
          clientID
          name
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDevice = /* GraphQL */ `
  subscription OnCreateDevice($filter: ModelSubscriptionDeviceFilterInput) {
    onCreateDevice(filter: $filter) {
      clientID
      name
      location
      description
      lastEvent {
        clientID
        createdAt
        type
        device {
          clientID
          name
          location
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        deviceName
        user {
          clientID
          name
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        userName
        updatedAt
        __typename
      }
      lastEventId
      lastEventCreatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDevice = /* GraphQL */ `
  subscription OnUpdateDevice($filter: ModelSubscriptionDeviceFilterInput) {
    onUpdateDevice(filter: $filter) {
      clientID
      name
      location
      description
      lastEvent {
        clientID
        createdAt
        type
        device {
          clientID
          name
          location
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        deviceName
        user {
          clientID
          name
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        userName
        updatedAt
        __typename
      }
      lastEventId
      lastEventCreatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDevice = /* GraphQL */ `
  subscription OnDeleteDevice($filter: ModelSubscriptionDeviceFilterInput) {
    onDeleteDevice(filter: $filter) {
      clientID
      name
      location
      description
      lastEvent {
        clientID
        createdAt
        type
        device {
          clientID
          name
          location
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        deviceName
        user {
          clientID
          name
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        userName
        updatedAt
        __typename
      }
      lastEventId
      lastEventCreatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
    onCreateUser(filter: $filter) {
      clientID
      name
      description
      lastEvent {
        clientID
        createdAt
        type
        device {
          clientID
          name
          location
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        deviceName
        user {
          clientID
          name
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        userName
        updatedAt
        __typename
      }
      lastEventId
      lastEventCreatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
    onUpdateUser(filter: $filter) {
      clientID
      name
      description
      lastEvent {
        clientID
        createdAt
        type
        device {
          clientID
          name
          location
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        deviceName
        user {
          clientID
          name
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        userName
        updatedAt
        __typename
      }
      lastEventId
      lastEventCreatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
    onDeleteUser(filter: $filter) {
      clientID
      name
      description
      lastEvent {
        clientID
        createdAt
        type
        device {
          clientID
          name
          location
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        deviceName
        user {
          clientID
          name
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        userName
        updatedAt
        __typename
      }
      lastEventId
      lastEventCreatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateEvent = /* GraphQL */ `
  subscription OnCreateEvent($filter: ModelSubscriptionEventFilterInput) {
    onCreateEvent(filter: $filter) {
      clientID
      createdAt
      type
      device {
        clientID
        name
        location
        description
        lastEvent {
          clientID
          createdAt
          type
          device {
            clientID
            name
            location
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          deviceName
          user {
            clientID
            name
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          userName
          updatedAt
          __typename
        }
        lastEventId
        lastEventCreatedAt
        createdAt
        updatedAt
        __typename
      }
      deviceName
      user {
        clientID
        name
        description
        lastEvent {
          clientID
          createdAt
          type
          device {
            clientID
            name
            location
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          deviceName
          user {
            clientID
            name
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          userName
          updatedAt
          __typename
        }
        lastEventId
        lastEventCreatedAt
        createdAt
        updatedAt
        __typename
      }
      userName
      updatedAt
      __typename
    }
  }
`;
export const onUpdateEvent = /* GraphQL */ `
  subscription OnUpdateEvent($filter: ModelSubscriptionEventFilterInput) {
    onUpdateEvent(filter: $filter) {
      clientID
      createdAt
      type
      device {
        clientID
        name
        location
        description
        lastEvent {
          clientID
          createdAt
          type
          device {
            clientID
            name
            location
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          deviceName
          user {
            clientID
            name
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          userName
          updatedAt
          __typename
        }
        lastEventId
        lastEventCreatedAt
        createdAt
        updatedAt
        __typename
      }
      deviceName
      user {
        clientID
        name
        description
        lastEvent {
          clientID
          createdAt
          type
          device {
            clientID
            name
            location
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          deviceName
          user {
            clientID
            name
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          userName
          updatedAt
          __typename
        }
        lastEventId
        lastEventCreatedAt
        createdAt
        updatedAt
        __typename
      }
      userName
      updatedAt
      __typename
    }
  }
`;
export const onDeleteEvent = /* GraphQL */ `
  subscription OnDeleteEvent($filter: ModelSubscriptionEventFilterInput) {
    onDeleteEvent(filter: $filter) {
      clientID
      createdAt
      type
      device {
        clientID
        name
        location
        description
        lastEvent {
          clientID
          createdAt
          type
          device {
            clientID
            name
            location
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          deviceName
          user {
            clientID
            name
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          userName
          updatedAt
          __typename
        }
        lastEventId
        lastEventCreatedAt
        createdAt
        updatedAt
        __typename
      }
      deviceName
      user {
        clientID
        name
        description
        lastEvent {
          clientID
          createdAt
          type
          device {
            clientID
            name
            location
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          deviceName
          user {
            clientID
            name
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          userName
          updatedAt
          __typename
        }
        lastEventId
        lastEventCreatedAt
        createdAt
        updatedAt
        __typename
      }
      userName
      updatedAt
      __typename
    }
  }
`;
export const onCreatePopup = /* GraphQL */ `
  subscription OnCreatePopup($filter: ModelSubscriptionPopupFilterInput) {
    onCreatePopup(filter: $filter) {
      clientID
      createdAt
      type
      resultType
      answeredCorrectly
      mouseXY
      buttonClicked
      startDateTime
      endDateTime
      device {
        clientID
        name
        location
        description
        lastEvent {
          clientID
          createdAt
          type
          device {
            clientID
            name
            location
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          deviceName
          user {
            clientID
            name
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          userName
          updatedAt
          __typename
        }
        lastEventId
        lastEventCreatedAt
        createdAt
        updatedAt
        __typename
      }
      deviceName
      user {
        clientID
        name
        description
        lastEvent {
          clientID
          createdAt
          type
          device {
            clientID
            name
            location
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          deviceName
          user {
            clientID
            name
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          userName
          updatedAt
          __typename
        }
        lastEventId
        lastEventCreatedAt
        createdAt
        updatedAt
        __typename
      }
      userName
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePopup = /* GraphQL */ `
  subscription OnUpdatePopup($filter: ModelSubscriptionPopupFilterInput) {
    onUpdatePopup(filter: $filter) {
      clientID
      createdAt
      type
      resultType
      answeredCorrectly
      mouseXY
      buttonClicked
      startDateTime
      endDateTime
      device {
        clientID
        name
        location
        description
        lastEvent {
          clientID
          createdAt
          type
          device {
            clientID
            name
            location
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          deviceName
          user {
            clientID
            name
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          userName
          updatedAt
          __typename
        }
        lastEventId
        lastEventCreatedAt
        createdAt
        updatedAt
        __typename
      }
      deviceName
      user {
        clientID
        name
        description
        lastEvent {
          clientID
          createdAt
          type
          device {
            clientID
            name
            location
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          deviceName
          user {
            clientID
            name
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          userName
          updatedAt
          __typename
        }
        lastEventId
        lastEventCreatedAt
        createdAt
        updatedAt
        __typename
      }
      userName
      updatedAt
      __typename
    }
  }
`;
export const onDeletePopup = /* GraphQL */ `
  subscription OnDeletePopup($filter: ModelSubscriptionPopupFilterInput) {
    onDeletePopup(filter: $filter) {
      clientID
      createdAt
      type
      resultType
      answeredCorrectly
      mouseXY
      buttonClicked
      startDateTime
      endDateTime
      device {
        clientID
        name
        location
        description
        lastEvent {
          clientID
          createdAt
          type
          device {
            clientID
            name
            location
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          deviceName
          user {
            clientID
            name
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          userName
          updatedAt
          __typename
        }
        lastEventId
        lastEventCreatedAt
        createdAt
        updatedAt
        __typename
      }
      deviceName
      user {
        clientID
        name
        description
        lastEvent {
          clientID
          createdAt
          type
          device {
            clientID
            name
            location
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          deviceName
          user {
            clientID
            name
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          userName
          updatedAt
          __typename
        }
        lastEventId
        lastEventCreatedAt
        createdAt
        updatedAt
        __typename
      }
      userName
      updatedAt
      __typename
    }
  }
`;
export const onCreateReport = /* GraphQL */ `
  subscription OnCreateReport($filter: ModelSubscriptionReportFilterInput) {
    onCreateReport(filter: $filter) {
      clientID
      type
      from
      to
      createdAt
      id
      updatedAt
      __typename
    }
  }
`;
export const onUpdateReport = /* GraphQL */ `
  subscription OnUpdateReport($filter: ModelSubscriptionReportFilterInput) {
    onUpdateReport(filter: $filter) {
      clientID
      type
      from
      to
      createdAt
      id
      updatedAt
      __typename
    }
  }
`;
export const onDeleteReport = /* GraphQL */ `
  subscription OnDeleteReport($filter: ModelSubscriptionReportFilterInput) {
    onDeleteReport(filter: $filter) {
      clientID
      type
      from
      to
      createdAt
      id
      updatedAt
      __typename
    }
  }
`;
