import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useI18n } from "../../contexts/I18nProvider";

/* interface LanguageSwitcherProps {
  onChangeLanguage?: (lang: string) => void;
} */

const LanguageSwitcher = () => {
  const { lang, setLang } = useI18n();

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 80 }} size="small">
        <InputLabel id="pref-lang-select-label"></InputLabel>
        <Select
          labelId="pref-lang-select-label"
          id="pref-lang-select"
          notched={false}
          value={lang}
          onChange={(e) => {
            setLang(e.target.value);
            localStorage.setItem("prefLang", e.target.value);
          }}
          autoWidth
        >
          <MenuItem value={"en-US"}>English</MenuItem>
          <MenuItem value={"fr-FR"}>Français</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};
export default LanguageSwitcher;
