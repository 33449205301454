import intlMessagesFR from "../locales/fr-FR.json";

import intlMessagesEN from "../locales/en-US.json";

type i18nConfig = {
  defaultLocale: string;

  availableLocales: string[];
};

const messagesMap: Record<string, any> = {
  "fr-FR": intlMessagesFR,

  "en-US": intlMessagesEN,
};

class LocaleService {
  private readonly defaultLocale: string;

  private readonly availableLocales: string[];

  constructor(config: i18nConfig) {
    this.defaultLocale = config.defaultLocale;

    this.availableLocales = config.availableLocales;
  }

  getAvailableLocales() {
    return this.availableLocales;
  }

  getDefaultLocale() {
    return this.defaultLocale;
  }

  getMessages(lang: string) {
    if (this.availableLocales.includes(lang)) {
      return messagesMap[lang];
    }

    return messagesMap[this.defaultLocale];
  }
}

const i18n =  new LocaleService({
  defaultLocale: "fr-FR",
  availableLocales: ["fr-FR", "en-US"],
});


export default i18n;