import { API, GraphQLQuery } from "@aws-amplify/api";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { pdf } from "@react-pdf/renderer";
import dayjs from "dayjs";
import "dayjs/locale/en-ca";
import "dayjs/locale/fr-ca";
import utc from "dayjs/plugin/utc";
import { saveAs } from "file-saver";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import * as APIt from "../../API";
import { Event, Report, ReportType } from "../../API";
import { useData } from "../../contexts/DataProvider";
import { useI18n } from "../../contexts/I18nProvider";
import * as queries from "../../graphql/queries";
import ReportPDF from "./ReportPDF";
import { getDatesFormat, getDocTitle, getReportType } from "./utils";

dayjs.extend(utc);

/**
 * @summary - Reports page that list list all clients reports sorted by most recent, possibility to view the report in a PDF format
 */

const Reports = () => {
  const { clientId } = useData();
  const [reports, setReports] = useState<Report[]>([]);
  const intl = useIntl();
  const { lang } = useI18n();

  async function fetchReportEvents(report: Report) {
    const variables: APIt.ListEventsQueryVariables = {
      clientID: clientId,
      createdAt: {
        between: [report.from, report.to],
      },
      sortDirection: APIt.ModelSortDirection.ASC,
    };

    const reportsData = await API.graphql<GraphQLQuery<APIt.ListEventsQuery>>({
      query: queries.listEvents,
      variables: variables,
    });

    console.log("report ", report.id);
    console.log("variables", variables);
    console.log("reportsData", reportsData);

    const events: Event[] = reportsData.data?.listEvents?.items as any;

    return events;
  }

  async function lazyGeneratePDF(report: Report, download: boolean = false) {
    try {
      //fetch pdf content
      const reportEvents = await fetchReportEvents(report);

      //pdf document layout
      const pdfDocument = (
        <ReportPDF report={report} events={reportEvents} intl={intl} />
      );

      //get the blob from the pdf doc
      const blob = await pdf(pdfDocument).toBlob();

      //download
      if (download) {
        const fileName = getDocTitle(report, intl);
        return saveAs(blob, fileName);
      }

      //visualize in new tab
      const objectUrl = window.URL.createObjectURL(blob);
      //(release memory used by blob url)
      // setTimeout(() => URL.revokeObjectURL(objectUrl), 5000);
      window.open(objectUrl, "_blank");
    } catch (error) {
      console.error("error processing report pdf  >>", error);
    }
  }

  useEffect(() => {
    async function fetchReports() {
      const variables: APIt.ReportsByClientIdAndCreatedAtQueryVariables = {
        clientID: clientId,
        sortDirection: APIt.ModelSortDirection.DESC,
      };
      try {
        const reportsData = await API.graphql<
          GraphQLQuery<APIt.ListReportsQuery>
        >({
          query: queries.listReports,
          variables: variables,
        });

        setReports(reportsData.data?.listReports?.items as any as Report[]);
      } catch (error) {
        console.error("error fetching reports", error);
      }
    }
    fetchReports();
  }, [clientId]);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              {intl.formatMessage({
                id: "report_productionDate",
                defaultMessage: "Date production",
              })}
            </TableCell>
            <TableCell>
              {intl.formatMessage({
                id: "report_recurrence",
                defaultMessage: "Récurrence",
              })}
            </TableCell>
            <TableCell>
              {intl.formatMessage({
                id: "report_period",
                defaultMessage: "Période",
              })}
            </TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reports.map((report) => {
            return (
              <TableRow
                key={`${report.clientID}-${report.createdAt}`}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {dayjs(report.createdAt)
                    .locale(`${lang.substring(0, 2)}-ca`)
                    .format("LL")}
                </TableCell>
                <TableCell>
                  {intl.formatMessage({
                    id: getReportType(report.type as ReportType),
                    defaultMessage: "Période",
                  })}
                </TableCell>
                <TableCell>{getDatesFormat(report)}</TableCell>
                <TableCell align="center">
                  <Button onClick={async () => lazyGeneratePDF(report)}>
                    PDF
                  </Button>
                  <Button onClick={async () => lazyGeneratePDF(report, true)}>
                    {intl.formatMessage({
                      id: "download",
                      defaultMessage: "Télécharger",
                    })}
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Reports;
