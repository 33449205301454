import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { Box, CssBaseline, styled, Toolbar } from "@mui/material";
import { Outlet } from "react-router-dom";
import AppBarTop from "../../components/AppBarTop/AppBarTop";
import Menu from "../../components/Menu/Menu";
import DataProvider from "../../contexts/DataProvider";

/**
 * @summary - Layout shared across the application.
 * If user is NOT logged-in display Amplify auth flow,
 * Else, display the shared layout and the current route content.
 */

const SharedLayout = () => {
  const { signOut } = useAuthenticator((context) => [context.user]);
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  return (
    <>
      {authStatus === "configuring" && "Loading..."}
      {authStatus !== "authenticated" ? (
        <AuthenticatorStyled />
      ) : (
        <DataProvider>
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBarTop signOut={signOut} />
            <Menu />
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                height: "100vh",
                bgcolor: "background.default",
                overflow:'auto',
                p: 3,
              }}
            >
              <Toolbar />
              <Outlet />
            </Box>
          </Box>
        </DataProvider>
      )}
    </>
  );
};

export default SharedLayout;

const AuthenticatorStyled = styled(Authenticator)`
  height: 100vh;
`;
