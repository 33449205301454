import { FC } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

/**
 * @summary - Link usign material's style for the router.
 * @param text - string value for the link
 * @param to - string value for the redirection
 *
 */

interface LinkRouterProps {
  text: string;
  to: string;
}

const LinkRouter: FC<LinkRouterProps> = ({ text, to }) => {
  return (
    <LinkStyled to={to} color="inherit">
      {text}
    </LinkStyled>
  );
};

export default LinkRouter;

const LinkStyled = styled(Link)`
  color: #a4a6b3;
  text-decoration: none;
`;
