import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import awsExports from "./aws-exports";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import App from "./scenes/App/App";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";

Amplify.configure(awsExports);

Sentry.init({
  dsn: "https://36141fcb659c4b37bb575ac4c72be9f2@o479572.ingest.sentry.io/4504814552481792",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],
  tracesSampleRate: 1.0,
  //Todo: uncomment for prod only??
  enabled: process.env.NODE_ENV !== "development",
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
