import { PowerSettingsNew } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Device } from "../../API";
import Grid from "../../components/Grid/Grid";
import Loading from "../../components/Loading/Loading";
import { useData } from "../../contexts/DataProvider";
import { formatDateTime, getStatusValue } from "../Reports/utils";

/**
 * @summary - Workspaces scene
 */

const Workspaces = () => {
  const { devices } = useData();
  const intl = useIntl();

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: intl.formatMessage({
        id: "workspaceName",
        defaultMessage: "Nom",
      }),
      flex: 1,
    },
    {
      field: "username",
      headerName: intl.formatMessage({
        id: "lastUser",
        defaultMessage: "Dernier utilisateur",
      }),
      flex: 1,
    },
    {
      field: "description",
      headerName: intl.formatMessage({
        id: "clientLegalName",
        defaultMessage: "Nom",
      }),
      flex: 1,
    },
    {
      field: "status",
      headerName: intl.formatMessage({
        id: "status",
        defaultMessage: "Statut",
      }),
      flex: 1,
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
          <PowerSettingsNew style={{ color: row?.powerIcon.color }} />
          <Typography>{row?.status}</Typography>
        </Stack>
      ),
    },
    {
      field: "time",
      headerName: intl.formatMessage({
        id: "workspaceTime",
        defaultMessage: "Heures",
      }),
      flex: 1,
    },
    {
      //TODO Recherches sur type:"actions"
      field: "actions",
      headerName: "",
      disableReorder: true,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      flex: 1,
      minWidth: 230,
      align: "center",
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <Stack
          direction="row"
          spacing={1}
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="contained"
            size="small"
            component={Link}
            to={`/events/deviceName/${row?.name}`}
            color="warning"
          >
            <Typography variant="body2">
              {intl.formatMessage({
                id: "eventPlural",
                defaultMessage: "Événements",
              })}
            </Typography>
          </Button>
          <Button
            variant="contained"
            size="small"
            component={Link}
            to={`/workspaces/${row?.name}`}
            color="success"
          >
            <Typography variant="body2">
              {intl.formatMessage({
                id: "editButton",
                defaultMessage: "Modifier",
              })}
            </Typography>
          </Button>
        </Stack>
      ),
    },
  ];

  const devicesWithId = devices.map((device: Device) => {
    const { text: statusValue, color } = getStatusValue(device.lastEvent || undefined, intl);
    const deviceName = device.lastEvent?.userName;
    const deviceTime = device.lastEventCreatedAt
      ? new Date(device.lastEventCreatedAt)
      : undefined;

    return {
      ...device,
      id: `${device.clientID}-${device.name}`,
      status: statusValue,
      time: `${formatDateTime(deviceTime, intl)}`,
      username: deviceName,
      powerIcon: { color },
    };
  });

  if (!devices) return <Loading />;

  return <Grid rows={devicesWithId} columns={columns} />;
};

export default Workspaces;
