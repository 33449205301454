import { AuthEventData } from "@aws-amplify/ui";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { useData } from "../../contexts/DataProvider";
import LanguageSwitcher from "./LanguageSwitcher";

/**
 * @summary - App Bar menu with title and profile informations
 * @param signOut - callback function from authenticator to sign out.
 *
 */

const AppBarTop = ({
  signOut,
}: {
  signOut: ((data?: AuthEventData | undefined) => void) | undefined;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { clientImage } = useData();
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const drawerWidth = 240;

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * @summary - Return the title from the url path
   * @param url - url path
   *
   */
  const getTtitle = (url: string) => {
    switch (url) {
      case "/":
        return intl.formatMessage({
          id: "home",
          defaultMessage: "Accueil",
        });
      case "/profile":
        return intl.formatMessage({
          id: "clientInfo",
          defaultMessage: "Profil Client",
        });
      // case "/reports":
      //   return intl.formatMessage({
      //     id: "reportPlural",
      //     defaultMessage: "Rapports",
      //   });
      case "/workspaces":
        return intl.formatMessage({
          id: "workspacePlural",
          defaultMessage: "Espaces de travail",
        });
      case "/users":
        return intl.formatMessage({
          id: "userPlural",
          defaultMessage: "Utilisateurs",
        });
      case "/popups":
        return "Popups";
      case url.match(new RegExp("/events/*"))?.input:
        return intl.formatMessage({
          id: "eventPlural",
          defaultMessage: "Événements",
        });
      case url.match(new RegExp("/users/*"))?.input:
        return intl.formatMessage({
          id: "editUser",
          defaultMessage: "Modifier utilisateur",
        });
      case url.match(new RegExp("/workspaces/*"))?.input:
        return intl.formatMessage({
          id: "editWorkspace",
          defaultMessage: "Modifier poste de travail",
        });
      default:
        return "";
    }
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        width: `calc(100% - ${drawerWidth}px)`,
        ml: `${drawerWidth}px`,
        backgroundColor: "rgba(54, 55, 64, 0.5)",
      }}
    >
      <Toolbar>
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
          {getTtitle(location.pathname)}
        </Typography>
        <Avatar
          alt="avatar"
          src={clientImage}
          onClick={(e) => handleClick(e)}
          sx={{ cursor: "context-menu" }}
        />
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              navigate("/profile");
              handleClose();
            }}
          >
            {intl.formatMessage({
              id: "clientInfo",
              defaultMessage: "Infos Client",
            })}
          </MenuItem>
          {/* <MenuItem
            onClick={() => {
              navigate("/reports");
              handleClose();
            }}
          >
            {intl.formatMessage({
              id: "reportPlural",
              defaultMessage: "Rapports",
            })}
          </MenuItem> */}
          <MenuItem onClick={signOut}>
            {intl.formatMessage({ id: "logOut", defaultMessage: "Logout" })}
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default AppBarTop;
