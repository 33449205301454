/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      legalName
      devices {
        items {
          clientID
          name
          location
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      users {
        items {
          clientID
          name
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      legalName
      devices {
        items {
          clientID
          name
          location
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      users {
        items {
          clientID
          name
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      id
      legalName
      devices {
        items {
          clientID
          name
          location
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      users {
        items {
          clientID
          name
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDevice = /* GraphQL */ `
  mutation CreateDevice(
    $input: CreateDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    createDevice(input: $input, condition: $condition) {
      clientID
      name
      location
      description
      lastEvent {
        clientID
        createdAt
        type
        device {
          clientID
          name
          location
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        deviceName
        user {
          clientID
          name
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        userName
        updatedAt
        __typename
      }
      lastEventId
      lastEventCreatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDevice = /* GraphQL */ `
  mutation UpdateDevice(
    $input: UpdateDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    updateDevice(input: $input, condition: $condition) {
      clientID
      name
      location
      description
      lastEvent {
        clientID
        createdAt
        type
        device {
          clientID
          name
          location
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        deviceName
        user {
          clientID
          name
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        userName
        updatedAt
        __typename
      }
      lastEventId
      lastEventCreatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDevice = /* GraphQL */ `
  mutation DeleteDevice(
    $input: DeleteDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    deleteDevice(input: $input, condition: $condition) {
      clientID
      name
      location
      description
      lastEvent {
        clientID
        createdAt
        type
        device {
          clientID
          name
          location
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        deviceName
        user {
          clientID
          name
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        userName
        updatedAt
        __typename
      }
      lastEventId
      lastEventCreatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      clientID
      name
      description
      lastEvent {
        clientID
        createdAt
        type
        device {
          clientID
          name
          location
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        deviceName
        user {
          clientID
          name
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        userName
        updatedAt
        __typename
      }
      lastEventId
      lastEventCreatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      clientID
      name
      description
      lastEvent {
        clientID
        createdAt
        type
        device {
          clientID
          name
          location
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        deviceName
        user {
          clientID
          name
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        userName
        updatedAt
        __typename
      }
      lastEventId
      lastEventCreatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      clientID
      name
      description
      lastEvent {
        clientID
        createdAt
        type
        device {
          clientID
          name
          location
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        deviceName
        user {
          clientID
          name
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        userName
        updatedAt
        __typename
      }
      lastEventId
      lastEventCreatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      clientID
      createdAt
      type
      device {
        clientID
        name
        location
        description
        lastEvent {
          clientID
          createdAt
          type
          device {
            clientID
            name
            location
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          deviceName
          user {
            clientID
            name
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          userName
          updatedAt
          __typename
        }
        lastEventId
        lastEventCreatedAt
        createdAt
        updatedAt
        __typename
      }
      deviceName
      user {
        clientID
        name
        description
        lastEvent {
          clientID
          createdAt
          type
          device {
            clientID
            name
            location
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          deviceName
          user {
            clientID
            name
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          userName
          updatedAt
          __typename
        }
        lastEventId
        lastEventCreatedAt
        createdAt
        updatedAt
        __typename
      }
      userName
      updatedAt
      __typename
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      clientID
      createdAt
      type
      device {
        clientID
        name
        location
        description
        lastEvent {
          clientID
          createdAt
          type
          device {
            clientID
            name
            location
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          deviceName
          user {
            clientID
            name
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          userName
          updatedAt
          __typename
        }
        lastEventId
        lastEventCreatedAt
        createdAt
        updatedAt
        __typename
      }
      deviceName
      user {
        clientID
        name
        description
        lastEvent {
          clientID
          createdAt
          type
          device {
            clientID
            name
            location
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          deviceName
          user {
            clientID
            name
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          userName
          updatedAt
          __typename
        }
        lastEventId
        lastEventCreatedAt
        createdAt
        updatedAt
        __typename
      }
      userName
      updatedAt
      __typename
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      clientID
      createdAt
      type
      device {
        clientID
        name
        location
        description
        lastEvent {
          clientID
          createdAt
          type
          device {
            clientID
            name
            location
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          deviceName
          user {
            clientID
            name
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          userName
          updatedAt
          __typename
        }
        lastEventId
        lastEventCreatedAt
        createdAt
        updatedAt
        __typename
      }
      deviceName
      user {
        clientID
        name
        description
        lastEvent {
          clientID
          createdAt
          type
          device {
            clientID
            name
            location
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          deviceName
          user {
            clientID
            name
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          userName
          updatedAt
          __typename
        }
        lastEventId
        lastEventCreatedAt
        createdAt
        updatedAt
        __typename
      }
      userName
      updatedAt
      __typename
    }
  }
`;
export const createPopup = /* GraphQL */ `
  mutation CreatePopup(
    $input: CreatePopupInput!
    $condition: ModelPopupConditionInput
  ) {
    createPopup(input: $input, condition: $condition) {
      clientID
      createdAt
      type
      resultType
      answeredCorrectly
      mouseXY
      buttonClicked
      startDateTime
      endDateTime
      device {
        clientID
        name
        location
        description
        lastEvent {
          clientID
          createdAt
          type
          device {
            clientID
            name
            location
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          deviceName
          user {
            clientID
            name
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          userName
          updatedAt
          __typename
        }
        lastEventId
        lastEventCreatedAt
        createdAt
        updatedAt
        __typename
      }
      deviceName
      user {
        clientID
        name
        description
        lastEvent {
          clientID
          createdAt
          type
          device {
            clientID
            name
            location
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          deviceName
          user {
            clientID
            name
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          userName
          updatedAt
          __typename
        }
        lastEventId
        lastEventCreatedAt
        createdAt
        updatedAt
        __typename
      }
      userName
      updatedAt
      __typename
    }
  }
`;
export const updatePopup = /* GraphQL */ `
  mutation UpdatePopup(
    $input: UpdatePopupInput!
    $condition: ModelPopupConditionInput
  ) {
    updatePopup(input: $input, condition: $condition) {
      clientID
      createdAt
      type
      resultType
      answeredCorrectly
      mouseXY
      buttonClicked
      startDateTime
      endDateTime
      device {
        clientID
        name
        location
        description
        lastEvent {
          clientID
          createdAt
          type
          device {
            clientID
            name
            location
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          deviceName
          user {
            clientID
            name
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          userName
          updatedAt
          __typename
        }
        lastEventId
        lastEventCreatedAt
        createdAt
        updatedAt
        __typename
      }
      deviceName
      user {
        clientID
        name
        description
        lastEvent {
          clientID
          createdAt
          type
          device {
            clientID
            name
            location
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          deviceName
          user {
            clientID
            name
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          userName
          updatedAt
          __typename
        }
        lastEventId
        lastEventCreatedAt
        createdAt
        updatedAt
        __typename
      }
      userName
      updatedAt
      __typename
    }
  }
`;
export const deletePopup = /* GraphQL */ `
  mutation DeletePopup(
    $input: DeletePopupInput!
    $condition: ModelPopupConditionInput
  ) {
    deletePopup(input: $input, condition: $condition) {
      clientID
      createdAt
      type
      resultType
      answeredCorrectly
      mouseXY
      buttonClicked
      startDateTime
      endDateTime
      device {
        clientID
        name
        location
        description
        lastEvent {
          clientID
          createdAt
          type
          device {
            clientID
            name
            location
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          deviceName
          user {
            clientID
            name
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          userName
          updatedAt
          __typename
        }
        lastEventId
        lastEventCreatedAt
        createdAt
        updatedAt
        __typename
      }
      deviceName
      user {
        clientID
        name
        description
        lastEvent {
          clientID
          createdAt
          type
          device {
            clientID
            name
            location
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          deviceName
          user {
            clientID
            name
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          userName
          updatedAt
          __typename
        }
        lastEventId
        lastEventCreatedAt
        createdAt
        updatedAt
        __typename
      }
      userName
      updatedAt
      __typename
    }
  }
`;
export const createReport = /* GraphQL */ `
  mutation CreateReport(
    $input: CreateReportInput!
    $condition: ModelReportConditionInput
  ) {
    createReport(input: $input, condition: $condition) {
      clientID
      type
      from
      to
      createdAt
      id
      updatedAt
      __typename
    }
  }
`;
export const updateReport = /* GraphQL */ `
  mutation UpdateReport(
    $input: UpdateReportInput!
    $condition: ModelReportConditionInput
  ) {
    updateReport(input: $input, condition: $condition) {
      clientID
      type
      from
      to
      createdAt
      id
      updatedAt
      __typename
    }
  }
`;
export const deleteReport = /* GraphQL */ `
  mutation DeleteReport(
    $input: DeleteReportInput!
    $condition: ModelReportConditionInput
  ) {
    deleteReport(input: $input, condition: $condition) {
      clientID
      type
      from
      to
      createdAt
      id
      updatedAt
      __typename
    }
  }
`;
