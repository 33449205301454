import { GraphQLQuery, GraphQLResult } from "@aws-amplify/api";
import {
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import {
  GetClientQueryVariables,
  UpdateClientInput,
  UpdateClientMutation,
} from "../../API";
import LanguageSwitcher from "../../components/AppBarTop/LanguageSwitcher";
import Loading from "../../components/Loading/Loading";
import { useData } from "../../contexts/DataProvider";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { Client } from "../../types";

/**
 * @summary - Edit Client Profile scene.
 * Also using REACT HOOK FORM LIBRARY : https://react-hook-form.com/
 */

type FormInputs = {
  legalName: string;
  preferedLang: string;
};

const Profile = () => {
  const navigate = useNavigate();
  const { clientId } = useData();
  const { clientImage, uploadClientImage } = useData();
  const [selectedFilePreview, setSelectedFilePreview] = useState<string>();
  const [selectedFile, setSelectedFile] = useState<File>();

  const intl = useIntl();

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isLoading, isDirty },
  } = useForm<FormInputs>({
    defaultValues: async () => await fetchClient(),
  });

  async function fetchClient() {
    const variables: GetClientQueryVariables = {
      id: clientId,
    };

    let apiData: GraphQLResult<GraphQLQuery<Client>>;

    try {
      apiData = await API.graphql<GraphQLQuery<Client>>({
        query: queries.getClient,
        variables,
      });
    } catch (error) {
      console.error(`error fetching client`, error);
    }
    //If record is not found, there is no error (object is simply null), so manually redirect somewhere if data is null.
    if (!apiData!.data?.getClient) {
      navigate("/ClientNotFound");
    }

    // Component should unmount when navigating to /404
    const { legalName } = apiData!.data?.getClient!;
    return { legalName, preferedLang: "en-US" };
  }

  async function updateClientInfo(formData: FormInputs) {
    const clientDetails: UpdateClientInput = {
      id: clientId,
      legalName: formData.legalName,
    };

    try {
      await API.graphql<GraphQLQuery<UpdateClientMutation>>({
        query: mutations.updateClient,
        variables: { input: clientDetails },
      });
    } catch (error) {
      console.error(error);
      //throw so the promise doesnt resolve for onSubmit method
      throw error;
    }
  }

  async function updateClientPhoto() {
    if (!selectedFile) return;
    try {
      await uploadClientImage(selectedFile);
    } catch (error) {
      console.error("Error uploading file: ", error);
      //throw so the promise doesnt resolve for onSubmit method
      throw error;
    }
  }

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setSelectedFile(file);
    }
  };

  /**
   *  @summary - Submit the form to edit the client. Only navigate away if both promises resolve. UpdateImage and UpdateInfo are two differents service
   *  @param formData - form inputs
   */
  const onSubmit: SubmitHandler<FormInputs> = async (formData) => {
    try {
      await updateClientInfo(formData);
      await updateClientPhoto();
      navigate(-1);
    } catch (_) {
      //do nothing with the error as they are already logged in the methods
    }
  };

  useEffect(() => {
    if (!selectedFile) return;

    const objectUrl = URL.createObjectURL(selectedFile!);
    setSelectedFilePreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  if (isLoading) return <Loading />;

  return (
    <Container component="main" maxWidth="xs">
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="label"
        >
          <input hidden accept="image/*" type="file" onChange={onFileChange} />
          <Avatar
            src={selectedFile ? selectedFilePreview : clientImage}
            sx={{
              m: 1,
              width: 100,
              height: 100,
            }}
          ></Avatar>
        </IconButton>

        <Controller
          control={control}
          name="legalName"
          rules={{
            required: {
              value: true,
              message: intl.formatMessage({
                id: "errorFieldRequired",
                defaultMessage: "Un nom légal est requis",
              }),
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              required
              margin="normal"
              fullWidth
              label={intl.formatMessage({
                id: "clientLegalName",
                defaultMessage: "Nom Legal",
              })}
              id="legalName"
              error={!!errors.legalName}
              helperText={errors.legalName?.message}
            />
          )}
        />

        <LanguageSwitcher />

        <Stack
          mt={2}
          width="100%"
          direction="row"
          justifyContent="flex-end"
          spacing={1}
        >
          <Button
            type="button"
            variant="contained"
            onClick={() => navigate(-1)}
          >
            {intl.formatMessage({ id: "backButton", defaultMessage: "Retour" })}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="success"
            disabled={
              (!selectedFile && (isSubmitting || !isDirty)) ||
              (selectedFile && isSubmitting)
            }
          >
            {intl.formatMessage({
              id: "editButton",
              defaultMessage: "Modifier",
            })}
          </Button>
        </Stack>
      </Box>
    </Container>
  );
};

export default Profile;
