import { Storage } from "aws-amplify";
import { useEffect, useState } from "react";

const useProfileImage = (clientId: string) => {
  const [clientImage, setClientImage] = useState<string | "">("");

  const refreshClientImage = async () => {
    //Load client image using its ID
    const signedURL = await Storage.get(clientId);
    // console.log(signedURL);
    setClientImage(signedURL);
  };

  const uploadClientImage = async (selectedFile: File) => {
    await Storage.put(clientId, selectedFile, {
      contentType: selectedFile.type,
    });

    await refreshClientImage();
  };

  useEffect(() => {
    refreshClientImage();
  }, []);

  return { clientImage, uploadClientImage };
};

export default useProfileImage;
