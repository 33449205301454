/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getClient = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      id
      legalName
      devices {
        items {
          clientID
          name
          location
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      users {
        items {
          clientID
          name
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        legalName
        devices {
          items {
            clientID
            name
            location
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        users {
          items {
            clientID
            name
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchClients = /* GraphQL */ `
  query SearchClients(
    $filter: SearchableClientFilterInput
    $sort: [SearchableClientSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableClientAggregationInput]
  ) {
    searchClients(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        legalName
        devices {
          items {
            clientID
            name
            location
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        users {
          items {
            clientID
            name
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getDevice = /* GraphQL */ `
  query GetDevice($clientID: ID!, $name: String!) {
    getDevice(clientID: $clientID, name: $name) {
      clientID
      name
      location
      description
      lastEvent {
        clientID
        createdAt
        type
        device {
          clientID
          name
          location
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        deviceName
        user {
          clientID
          name
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        userName
        updatedAt
        __typename
      }
      lastEventId
      lastEventCreatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDevices = /* GraphQL */ `
  query ListDevices(
    $clientID: ID
    $name: ModelStringKeyConditionInput
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDevices(
      clientID: $clientID
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        clientID
        name
        location
        description
        lastEvent {
          clientID
          createdAt
          type
          device {
            clientID
            name
            location
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          deviceName
          user {
            clientID
            name
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          userName
          updatedAt
          __typename
        }
        lastEventId
        lastEventCreatedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchDevices = /* GraphQL */ `
  query SearchDevices(
    $filter: SearchableDeviceFilterInput
    $sort: [SearchableDeviceSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableDeviceAggregationInput]
  ) {
    searchDevices(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        clientID
        name
        location
        description
        lastEvent {
          clientID
          createdAt
          type
          device {
            clientID
            name
            location
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          deviceName
          user {
            clientID
            name
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          userName
          updatedAt
          __typename
        }
        lastEventId
        lastEventCreatedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($clientID: ID!, $name: String!) {
    getUser(clientID: $clientID, name: $name) {
      clientID
      name
      description
      lastEvent {
        clientID
        createdAt
        type
        device {
          clientID
          name
          location
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        deviceName
        user {
          clientID
          name
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        userName
        updatedAt
        __typename
      }
      lastEventId
      lastEventCreatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $clientID: ID
    $name: ModelStringKeyConditionInput
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      clientID: $clientID
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        clientID
        name
        description
        lastEvent {
          clientID
          createdAt
          type
          device {
            clientID
            name
            location
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          deviceName
          user {
            clientID
            name
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          userName
          updatedAt
          __typename
        }
        lastEventId
        lastEventCreatedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: [SearchableUserSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUserAggregationInput]
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        clientID
        name
        description
        lastEvent {
          clientID
          createdAt
          type
          device {
            clientID
            name
            location
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          deviceName
          user {
            clientID
            name
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          userName
          updatedAt
          __typename
        }
        lastEventId
        lastEventCreatedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($clientID: ID!, $createdAt: AWSDateTime!) {
    getEvent(clientID: $clientID, createdAt: $createdAt) {
      clientID
      createdAt
      type
      device {
        clientID
        name
        location
        description
        lastEvent {
          clientID
          createdAt
          type
          device {
            clientID
            name
            location
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          deviceName
          user {
            clientID
            name
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          userName
          updatedAt
          __typename
        }
        lastEventId
        lastEventCreatedAt
        createdAt
        updatedAt
        __typename
      }
      deviceName
      user {
        clientID
        name
        description
        lastEvent {
          clientID
          createdAt
          type
          device {
            clientID
            name
            location
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          deviceName
          user {
            clientID
            name
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          userName
          updatedAt
          __typename
        }
        lastEventId
        lastEventCreatedAt
        createdAt
        updatedAt
        __typename
      }
      userName
      updatedAt
      __typename
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $clientID: ID
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEvents(
      clientID: $clientID
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        clientID
        createdAt
        type
        device {
          clientID
          name
          location
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        deviceName
        user {
          clientID
          name
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        userName
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchEvents = /* GraphQL */ `
  query SearchEvents(
    $filter: SearchableEventFilterInput
    $sort: [SearchableEventSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableEventAggregationInput]
  ) {
    searchEvents(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        clientID
        createdAt
        type
        device {
          clientID
          name
          location
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        deviceName
        user {
          clientID
          name
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        userName
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getPopup = /* GraphQL */ `
  query GetPopup($clientID: ID!, $createdAt: AWSDateTime!) {
    getPopup(clientID: $clientID, createdAt: $createdAt) {
      clientID
      createdAt
      type
      resultType
      answeredCorrectly
      mouseXY
      buttonClicked
      startDateTime
      endDateTime
      device {
        clientID
        name
        location
        description
        lastEvent {
          clientID
          createdAt
          type
          device {
            clientID
            name
            location
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          deviceName
          user {
            clientID
            name
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          userName
          updatedAt
          __typename
        }
        lastEventId
        lastEventCreatedAt
        createdAt
        updatedAt
        __typename
      }
      deviceName
      user {
        clientID
        name
        description
        lastEvent {
          clientID
          createdAt
          type
          device {
            clientID
            name
            location
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          deviceName
          user {
            clientID
            name
            description
            lastEventId
            lastEventCreatedAt
            createdAt
            updatedAt
            __typename
          }
          userName
          updatedAt
          __typename
        }
        lastEventId
        lastEventCreatedAt
        createdAt
        updatedAt
        __typename
      }
      userName
      updatedAt
      __typename
    }
  }
`;
export const listPopups = /* GraphQL */ `
  query ListPopups(
    $clientID: ID
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelPopupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPopups(
      clientID: $clientID
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        clientID
        createdAt
        type
        resultType
        answeredCorrectly
        mouseXY
        buttonClicked
        startDateTime
        endDateTime
        device {
          clientID
          name
          location
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        deviceName
        user {
          clientID
          name
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        userName
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchPopups = /* GraphQL */ `
  query SearchPopups(
    $filter: SearchablePopupFilterInput
    $sort: [SearchablePopupSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablePopupAggregationInput]
  ) {
    searchPopups(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        clientID
        createdAt
        type
        resultType
        answeredCorrectly
        mouseXY
        buttonClicked
        startDateTime
        endDateTime
        device {
          clientID
          name
          location
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        deviceName
        user {
          clientID
          name
          description
          lastEvent {
            clientID
            createdAt
            type
            deviceName
            userName
            updatedAt
            __typename
          }
          lastEventId
          lastEventCreatedAt
          createdAt
          updatedAt
          __typename
        }
        userName
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getReport = /* GraphQL */ `
  query GetReport($id: ID!) {
    getReport(id: $id) {
      clientID
      type
      from
      to
      createdAt
      id
      updatedAt
      __typename
    }
  }
`;
export const listReports = /* GraphQL */ `
  query ListReports(
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        clientID
        type
        from
        to
        createdAt
        id
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const reportsByClientIdAndCreatedAt = /* GraphQL */ `
  query ReportsByClientIdAndCreatedAt(
    $clientID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportsByClientIdAndCreatedAt(
      clientID: $clientID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        clientID
        type
        from
        to
        createdAt
        id
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchReports = /* GraphQL */ `
  query SearchReports(
    $filter: SearchableReportFilterInput
    $sort: [SearchableReportSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableReportAggregationInput]
  ) {
    searchReports(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        clientID
        type
        from
        to
        createdAt
        id
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
