import { Button, Stack, Typography } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import Grid from "../../components/Grid/Grid";

import { useIntl } from "react-intl";
import { User } from "../../API";
import Loading from "../../components/Loading/Loading";
import { useData } from "../../contexts/DataProvider";
import { formatDateTime, getStatusValue } from "../Reports/utils";
import { PowerSettingsNew } from "@mui/icons-material";

/**
 * @summary - Users scene
 */

const Users = () => {
  const { users } = useData();
  const intl = useIntl();

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: intl.formatMessage({ id: "userName", defaultMessage: "Nom" }),
      flex: 1,
    },
    {
      field: "description",
      headerName: intl.formatMessage({
        id: "clientLegalName",
        defaultMessage: "Nom",
      }),
      flex: 1,
    },
    {
      field: "workspace",
      headerName: intl.formatMessage({
        id: "workspaceSingle",
        defaultMessage: "Poste de travail",
      }),
      flex: 1,
    },
    {
      field: "status",
      headerName: intl.formatMessage({
        id: "status",
        defaultMessage: "Statut",
      }),
      flex: 1,
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <Stack direction="row" spacing={1} justifyContent="center" alignItems="center" gap="10px">
          {row?.powerIcon}
          {row?.status}
        </Stack>
      ),
    },
    {
      field: "time",
      headerName: intl.formatMessage({
        id: "workspaceTime",
        defaultMessage: "Heures",
      }),
      flex: 1,
    },
    {
      //TODO Recherches sur type:"actions"
      field: "actions",
      headerName: "",
      disableReorder: true,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      flex: 1,
      minWidth: 230,
      align: "center",
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <Stack
          direction="row"
          spacing={1}
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="contained"
            size="small"
            component={Link}
            to={`/events/userName/${encodeURI(row.name)}`}
          >
            <Typography variant="body2">
              {intl.formatMessage({
                id: "eventPlural",
                defaultMessage: "Événements",
              })}
            </Typography>
          </Button>
          <Button
            variant="contained"
            size="small"
            component={Link}
            to={`/users/${encodeURI(row?.name)}`}
            color="success"
          >
            <Typography variant="body2">
              {intl.formatMessage({
                id: "editButton",
                defaultMessage: "Modifier",
              })}
            </Typography>
          </Button>
        </Stack>
      ),
    },
  ];

  const usersWithId = users.map((user: User) => {
    const { text: statusValue, color } = getStatusValue(user.lastEvent || undefined, intl);
    const userName = user.lastEvent?.userName;
    const userDescription = user.lastEvent?.device?.description;
    const userTime = user.lastEventCreatedAt
      ? new Date(user.lastEventCreatedAt)
      : undefined;

    return {
      ...user,
      id: `${user.clientID}-${user.name}`,
      status: statusValue,
      time: `${formatDateTime(userTime, intl)}`,
      username: userName,
      workspace: userDescription,
      // Use the color in your MUI Icon
      powerIcon: <PowerSettingsNew style={{ color }} />,
    };
  });

  if (!users) return <Loading />;

  return <Grid rows={usersWithId} columns={columns} />;
};

export default Users;
