import { PowerSettingsNew } from "@mui/icons-material";
import { style } from "@mui/system";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { IntlShape } from "react-intl";
import { EventTypeCode, Report, ReportType } from "../../API";
import("dayjs/locale/fr-ca");
import("dayjs/locale/en-ca");
dayjs.extend(utc);

export function getReportType(reportType: ReportType, isPDF: boolean = false) {
  switch (reportType) {
    case ReportType.daily:
      return isPDF ? "reportType_daily" : "daily";
    case ReportType.weekly:
      return isPDF ? "reportType_weekly" : "weekly";
    case ReportType.monthly:
      return isPDF ? "reportType_monthly" : "monthly";
    default:
      return "Unkown record type";
  }
}
export function getDatesFormat(report: Report) {
  const prefLang = localStorage.getItem("prefLang") ?? "fr";
  switch (report.type) {
    case ReportType.daily:
      return dayjs(report.to)
        .locale(`${prefLang.substring(0, 2)}-ca`)
        .format("LL");
    case ReportType.weekly:
      return `${dayjs(report.from)
        .locale(`${prefLang.substring(0, 2)}-ca`)
        .format("LL")} - ${dayjs(report.to)
        .locale(`${prefLang.substring(0, 2)}-ca`)
        .format("LL")}`;
    case ReportType.monthly:
      return `${dayjs(report.from)
        .locale(`${prefLang.substring(0, 2)}-ca`)
        .format("LL")} - ${dayjs(report.to)
        .locale(`${prefLang.substring(0, 2)}-ca`)
        .format("LL")}`;
    default:
      return `${dayjs(report.from)
        .locale(`${prefLang.substring(0, 2)}-ca`)
        .format("LL")} - ${dayjs(report.to)
        .locale(`${prefLang.substring(0, 2)}-ca`)
        .format("LL")}`;
  }
}

export function getDocTitle(report: Report, intl: IntlShape) {
  const docTitle = `${intl.formatMessage({
    id: getReportType(report.type as ReportType, true),
    defaultMessage: "reportType",
  })} - ${getDatesFormat(report)}`;

  return docTitle;
}

const calculateRelativeTime = (date: Date, intl: IntlShape) => {
  const now = new Date();
  const diffInMilliseconds = now.getTime() - date.getTime();
  const diffInSeconds = Math.floor(diffInMilliseconds / 1000);

  if (diffInSeconds < 60) {
    return intl.formatRelativeTime(-diffInSeconds, 'second');
  } else if (diffInSeconds < 3600) {
    return intl.formatRelativeTime(-Math.floor(diffInSeconds / 60), 'minute');
  } else if (diffInSeconds < 86400) {
    return intl.formatRelativeTime(-Math.floor(diffInSeconds / 3600), 'hour');
  } else {
    return intl.formatRelativeTime(-Math.floor(diffInSeconds / 86400), 'day');
  }
};

export const formatDateTime = (date: string | number | Date | undefined, intl: IntlShape) => {
  if (!date) return "";

  const formattedDate = intl.formatDate(date, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });

  const relativeTime = calculateRelativeTime(new Date(date), intl);

  return `${formattedDate} (${relativeTime})`;
};

export const getStatusValue = (lastEvent: { type?: EventTypeCode } | undefined, intl: IntlShape) => {
  if (!lastEvent || !lastEvent.type) {
    return { text: "", color: "" };
  }

  switch (lastEvent.type as string) {
    case EventTypeCode.applicationStart:
      return { text: intl.formatMessage({ id: "online", defaultMessage: "En ligne" }), color: "green" };
    case EventTypeCode.applicationStop:
      return { text: intl.formatMessage({ id: "offline", defaultMessage: "Hors ligne" }), color: "red" };
    case EventTypeCode.sessionStart:
      return { text: intl.formatMessage({ id: "online", defaultMessage: "En ligne" }), color: "green" };
    case EventTypeCode.sessionStop:
      return { text: intl.formatMessage({ id: "offline", defaultMessage: "Hors ligne" }), color: "red" };
    case EventTypeCode.sessionLock:
      return { text: intl.formatMessage({ id: "away", defaultMessage: "Absent" }), color: "orange" };
    case EventTypeCode.sessionUnlock:
      return { text: intl.formatMessage({ id: "online", defaultMessage: "En ligne" }), color: "green" };
    case EventTypeCode.screenSaverStart:
      return { text: intl.formatMessage({ id: "away", defaultMessage: "Absent" }), color: "orange" };
    case EventTypeCode.screenSaverStop:
      return { text: intl.formatMessage({ id: "online", defaultMessage: "En ligne" }), color: "green" };
    default:
      return { text: "", color: "" };
  }
};