import { GridColDef } from "@mui/x-data-grid";
//import { getGridDateOperators } from "@mui/x-data-grid-pro";
import { API, GraphQLQuery } from "@aws-amplify/api";
import dayjs from "dayjs";
import "dayjs/locale/en-ca";
import "dayjs/locale/fr-ca";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import {
  Event,
  SearchEventsQuery,
  SearchEventsQueryVariables,
  SearchableEventConnection,
} from "../../API";
import { useData } from "../../contexts/DataProvider";
import { useI18n } from "../../contexts/I18nProvider";
import * as queries from "../../graphql/queries";
import EventsGrid from "./EventsGrid";
import Filter from "./Filter";
import { IFormData } from "./IFormData";

/**
 * @summary - Events scene.
 * @param fieldType - For navigation filtering. Determines where the user comes from ("Espaces de travail" ou "Utilisateurs") to filter accordingly
 */

interface IProps {
  fieldType?: "deviceName" | "userName";
}

interface ICachedEvents {
  page: number;
  nextToken: string | null;
  events: Event[];
}

const rowsPerPage = 50;

const Events = ({ fieldType }: IProps) => {
  const { clientId, users, devices } = useData();
  const { value } = useParams();

  const [loading, setLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: rowsPerPage,
  });
  const [cachedEvents, setCachedEvents] = useState<ICachedEvents[]>([]);

  const intl = useIntl();
  const { lang } = useI18n();
  const columns: GridColDef[] = [
    {
      field: "createdAt",
      type: "date",
      headerName: "Date",
      valueFormatter: (params) =>
        dayjs(params?.value)
          .locale(`${lang.substring(0, 2)}-ca`)
          .format("llll"),
      flex: 1,
    },
    {
      field: "type",
      headerName: intl.formatMessage({
        id: "eventType",
        defaultMessage: "Type Événement",
      }),
      valueFormatter: (params) =>
        intl.formatMessage({ id: params.value, defaultMessage: params.value }),
      flex: 1,
      filterable: false,
    },
    {
      field: "deviceName",
      headerName: intl.formatMessage({
        id: "workspaceSingle",
        defaultMessage: "Poste de travail",
      }),
      flex: 1,
      type: "singleSelect",
      valueOptions: devices.map((device) => ({
        value: device.name,
        label: device.name,
      })),
    },
    {
      field: "userName",
      headerName: intl.formatMessage({
        id: "userSingle",
        defaultMessage: "Utilisateur",
      }),
      flex: 1,
      type: "singleSelect",
      valueOptions: users.map((user) => ({
        value: user.name,
        label: user.name,
      })),
    },
  ];

  const { handleSubmit, control, getValues } = useForm<IFormData>({
    defaultValues: {
      users: fieldType === "userName" ? [{ name: value }] : [],
      devices: fieldType === "deviceName" ? [{ name: value }] : [],
      dates: [dayjs().startOf("day"), dayjs().endOf("day")],
    },
  });

  const onSubmit: SubmitHandler<IFormData> = async (data) => {
    setLoading(true);
    setCachedEvents([]);
    const result = await fetchEvents(data);
    const { items, total, nextToken } = result;
    setRowCount(total!);
    setCachedEvents((prev) => {
      const visited = prev[paginationModel.page];
      if (visited) return prev;
      return [
        ...prev,
        {
          page: paginationModel.page,
          nextToken: nextToken ?? null,
          events: items as any as Event[],
        },
      ];
    });
    setLoading(false);
  };

  const fetchEvents = async (data: IFormData) => {
    const { users, devices, dates } = data;
    const anySelectedValues = users.length || devices.length;
    const variables: SearchEventsQueryVariables = {
      filter: {
        clientID: {
          eq: clientId,
        },
        ...(anySelectedValues && {
          or: [
            ...users.map((u) => {
              return {
                userName: {
                  eq: u.name,
                },
              };
            }),
            ...devices.map((d) => {
              return {
                deviceName: {
                  eq: d.name,
                },
              };
            }),
          ],
        }),
        createdAt: {
          gt: dates[0]!.startOf("day").toISOString(),
          lt: dates[1]!.endOf("day").toISOString(),
        },
      },
      limit: rowsPerPage,
      nextToken: cachedEvents[paginationModel.page - 1]?.nextToken,
    };

    const result = await API.graphql<GraphQLQuery<SearchEventsQuery>>({
      query: queries.searchEvents,
      variables: variables,
    });

    return result.data?.searchEvents as SearchableEventConnection;
  };

  useEffect(() => {
    setLoading(true);
    const visited = cachedEvents[paginationModel.page];
    if (visited) return setLoading(false);

    fetchEvents(getValues())
      .then((result) => {
        const { items, total, nextToken } = result;
        setRowCount(total!);
        setCachedEvents((prev) => {
          const visited = prev[paginationModel.page];
          if (visited) return prev;
          return [
            ...prev,
            {
              page: paginationModel.page,
              nextToken: nextToken ?? null,
              events: items as any as Event[],
            },
          ];
        });
      })
      .catch((error) => console.log("error >>", error))
      .finally(() => setLoading(false));
  }, [paginationModel.page]); //eslint-disable-line

  return (
    <>
      <Filter
        control={control}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
      />
      <EventsGrid
        rows={cachedEvents[paginationModel.page]?.events ?? []}
        columns={columns}
        rowCountState={rowCount}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        isLoading={loading}
        rowsPerPage={rowsPerPage}
      />
    </>
  );
};

export default Events;
