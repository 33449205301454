import { FC } from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";

/**
 * @summary - Card for displaying some informations
 * @param text - string value to display
 * @param number - number value to display
 */

interface CardInfoProps {
  text: string;
  number: number;
}

const LinkRouter: FC<CardInfoProps> = ({ text, number }) => {
  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">
        <CardContent>
          <Typography
            sx={{ fontSize: 18, textAlign: "center" }}
            color="text.secondary"
            gutterBottom
          >
            {text}
          </Typography>
          <Typography
            sx={{ fontSize: 26, textAlign: "center" }}
            color="text.secondary"
            gutterBottom
          >
            {number}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default LinkRouter;
