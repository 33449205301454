import { useAuthenticator } from "@aws-amplify/ui-react";
import React from "react";
import { Device, User } from "../API";
import useDevices from "../hooks/useDevices";
import useProfileImage from "../hooks/useProfileImage";
import useUsers from "../hooks/userUsers";

export interface IData {
  devices: Device[];
  users: User[];
  clientId: string;
  clientImage: string;
  uploadClientImage: (selectedFile: File) => Promise<void>;
}

/**
 * @summary - Context that store client's users and workspaces. Also exposes methods to update users or works states.
 */

export const DataContext = React.createContext<IData | undefined>(undefined);

export const useData = () => {
  const context = React.useContext(DataContext);
  if (context === undefined) {
    throw new Error("useData must be used within a DataProvider");
  }
  return context;
};

const DataProvider = ({ children }: { children: React.ReactNode }) => {
  const { user } = useAuthenticator((context) => [context.user]);
  const clientId = (user as any).signInUserSession.accessToken.payload[
    "cognito:groups"
  ][0];

  const { clientImage, uploadClientImage } = useProfileImage(clientId);

  const { users } = useUsers(clientId);
  const { devices } = useDevices(clientId);

  return (
    <DataContext.Provider
      value={{
        devices: devices,
        users: users,
        clientId: clientId,
        clientImage: clientImage,
        uploadClientImage: uploadClientImage,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;
