import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import LinkMenu from "./LinkMenu";
import Typography from "@mui/material/Typography";
import { useIntl } from "react-intl";
import pjson from "../../../package.json";

/**
 * @summary - App Menu using a permanent drawer
 *
 */

const Menu = () => {
  const intl = useIntl();
  const drawerWidth = 240;

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          backgroundColor: "#363740",
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar sx={{ justifyContent: "center" }}>
        <Typography variant="h6" color="#a4a6b3">
          RUThere
        </Typography>
      </Toolbar>
      <Divider />
      <List>
        <LinkMenu
          text={intl.formatMessage({
            id: "home",
            defaultMessage: "Vue générale",
          })}
          to="/"
          nameIcon="VisibilityIcon"
        />
        <LinkMenu
          text={intl.formatMessage({
            id: "userPlural",
            defaultMessage: "Utilisateurs",
          })}
          to="/users"
          nameIcon="PeopleIcon"
        />
        <LinkMenu
          text={intl.formatMessage({
            id: "workspacePlural",
            defaultMessage: "Espaces de travail",
          })}
          to="/workspaces"
          nameIcon="WorkspacesIcon"
        />
        <LinkMenu
          text={intl.formatMessage({
            id: "eventPlural",
            defaultMessage: "Événements",
          })}
          to="/events"
          nameIcon="EventIcon"
        />
        <LinkMenu text="Pop-ups" to="/popups" nameIcon="AnnouncementIcon" />
        <LinkMenu
          text="Rapports"
          to="/reports"
          nameIcon="AssessmentIcon"/>
      </List>
      <Divider />
      <Typography padding="8px 16px" marginTop='auto' color='#a4a6b3' >version {pjson.version} <br/>
      <div style={{fontSize: '0.4em', color: 'rgba(0,0,0,0.5)'}
      }>
      Build {process.env.REACT_APP_BUILD_VERSION}<br/>
      Commit {process.env.REACT_APP_BUILD_COMMIT}
      </div>
      </Typography>
    </Drawer>
  );
};

export default Menu;
