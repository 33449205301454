import Paper from "@mui/material/Paper";
import {
  DataGridPro,
  GridColDef,
  GridRowsProp,
  GridToolbar,
} from "@mui/x-data-grid-pro";
import { FC } from "react";

/**
 * @summary - Material-ui grid-data, to display events.
 * @param rows - props for setting the data
 * @param columns - props for setting the columns definitions
 */

interface GridProps {
  rows: GridRowsProp;
  columns: GridColDef[];
  rowCountState?: number;
  isLoading?: boolean;
  paginationModel?: {
    page: number;
    pageSize: number;
  };
  setPaginationModel?: React.Dispatch<
    React.SetStateAction<{
      page: number;
      pageSize: number;
    }>
  >;
  rowsPerPage: number;
}

const EventsGrid: FC<GridProps> = ({
  rows,
  columns,
  rowCountState,
  paginationModel,
  setPaginationModel,
  isLoading,
  rowsPerPage,
}) => {
  const topBarHeight = 166;

  return (
    <Paper
      data-testid="grid"
      elevation={1}
      sx={{ height: `calc(100% - ${topBarHeight}px)` }}
    >
      <DataGridPro
        loading={isLoading}
        pagination
        paginationMode="server"
        pageSizeOptions={[rowsPerPage]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        rowCount={rowCountState}
        disableColumnMenu
        disableColumnFilter
        getRowId={(row) => `${row.clientID}-${row.createdAt}`}
        rows={rows}
        columns={columns}
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </Paper>
  );
};

export default EventsGrid;
