import { Box } from "@mui/material";
import CardInfo from "../../components/CardInfo/CardInfo";
import { useData } from "../../contexts/DataProvider";
import { useIntl } from "react-intl";

/**
 * @summary - Home page displaying workspaces and users count
 */

const Home = () => {
  const { devices, users } = useData();
  const intl = useIntl();

  return (
    <Box
      sx={{
        display: "flex",
        gap: "10px",
        justifyContent: "space-evenly",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      <CardInfo
        text={intl.formatMessage({
          id: "workspacePlural",
          defaultMessage: "Espaces de travail",
        })}
        number={devices?.length || 0}
      />
      <CardInfo
        text={intl.formatMessage({
          id: "userPlural",
          defaultMessage: "Utilisateurs",
        })}
        number={users?.length || 0}
      />
    </Box>
  );
};

export default Home;
