import { API, GraphQLQuery, GraphQLSubscription } from "@aws-amplify/api";
import { GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";
import "dayjs/locale/en-ca";
import "dayjs/locale/fr-ca";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  ListPopupsQuery,
  ListPopupsQueryVariables,
  OnCreatePopupSubscription,
  OnCreatePopupSubscriptionVariables,
  Popup,
} from "../../API";
import Grid from "../../components/Grid/Grid";
import Loading from "../../components/Loading/Loading";
import { useData } from "../../contexts/DataProvider";
import { useI18n } from "../../contexts/I18nProvider";
import * as queries from "../../graphql/queries";
import * as subscriptions from "../../graphql/subscriptions";

const Popups = () => {
  const { clientId } = useData();
  const [popups, setPopups] = useState<Popup[]>([]);
  const intl = useIntl();
  const { lang } = useI18n();

  const columns: GridColDef[] = [
    {
      field: "startDateTime",
      headerName: intl.formatMessage({
        id: "popupStart",
        defaultMessage: "Date début",
      }),
      flex: 1,
    },
    {
      field: "endDateTime",
      headerName: intl.formatMessage({
        id: "popupEnd",
        defaultMessage: "Date fin",
      }),
      flex: 1,
    },
    {
      field: "type",
      headerName: intl.formatMessage({
        id: "popupType",
        defaultMessage: "Type",
      }),
      valueFormatter: (params) =>
        intl.formatMessage({ id: params.value, defaultMessage: params.value }),
      flex: 1,
    },
    {
      field: "resultType",
      headerName: intl.formatMessage({
        id: "popupResultType",
        defaultMessage: "Type résultat",
      }),
      valueFormatter: (params) =>
        intl.formatMessage({ id: params.value, defaultMessage: params.value }),
      flex: 1,
    },
    {
      field: "deviceName",
      headerName: intl.formatMessage({
        id: "workspaceSingle",
        defaultMessage: "Poste de travail",
      }),
      flex: 1,
    },
    {
      field: "userName",
      headerName: intl.formatMessage({
        id: "userSingle",
        defaultMessage: "Utilisateur",
      }),
      flex: 1,
    },
  ];

  useEffect(() => {
    async function fetchPopups() {
      const variables: ListPopupsQueryVariables = {
        clientID: clientId,
      };

      try {
        const popupsData = await API.graphql<GraphQLQuery<ListPopupsQuery>>({
          query: queries.listPopups,
          variables: variables,
        });
        setPopups(popupsData.data?.listPopups?.items as Popup[]);
      } catch (error) {
        console.error("error fetching popups", error);
      }
    }
    fetchPopups();
    function subscribePopupCreate() {
      const callback = (createdPopup: Popup) => {
        //Important to use prev State, because devices might be not fetched yet (async code in)
        setPopups((prev) => [...prev, { ...createdPopup }]);
      };

      const variables: OnCreatePopupSubscriptionVariables = {
        filter: {
          // Only receive Popups messages where the "clientId" field is clientId
          clientID: {
            eq: clientId,
          },
        },
      };

      const subscription = API.graphql<
        GraphQLSubscription<OnCreatePopupSubscription>
      >({
        query: subscriptions.onCreatePopup,
        variables: variables,
      }).subscribe({
        next: ({ value }) =>
          callback(value.data?.onCreatePopup as any as Popup),
        error: (error) => console.error(error),
      });

      return subscription;
    }
    const sub = subscribePopupCreate();
    return () => sub.unsubscribe();
  }, [clientId]);

  const popupsWithDates = popups.map((popup: Popup) => ({
    ...popup,
    startDateTime: dayjs(popup.startDateTime)
      .locale(`${lang.substring(0, 2)}-ca`)
      .format("ll LTS"),
    endDateTime: dayjs(popup.endDateTime)
      .locale(`${lang.substring(0, 2)}-ca`)
      .format("ll LTS"),
    id: `${popup.clientID}-${popup.createdAt}`,
  }));

  if (!popups) return <Loading />;

  return <Grid rows={popupsWithDates} columns={columns} />;
};

export default Popups;
