import { Authenticator, translations } from "@aws-amplify/ui-react";
import { LicenseInfo } from "@mui/x-license-pro";
import Router from "../../components/Router/Router";
import { I18n as AmplifyI18n } from "aws-amplify";
import I18nProvider from "../../contexts/I18nProvider";

/**
 * @summary - Root app component
 *
 */
AmplifyI18n.putVocabularies(translations);
AmplifyI18n.setLanguage("fr");

AmplifyI18n.putVocabularies({
  fr: {
    "Sign In": "Connexion",
    Email: "Courriel",
    "Enter your Email": "Entrez votre adresse courriel",
    "Enter your Password": "Entrez votre mot de passe",
    "Please confirm your Password": "Veuillez confirmer votre mot de passe",
  },
});

LicenseInfo.setLicenseKey(
  "3305a84987c02d8b2bfe6f29e83b1b6fTz04NzMzOSxFPTE3NDMxODkwNDIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

const App = () => {
  return (
    <Authenticator.Provider>
      <I18nProvider>
        <Router />
      </I18nProvider>
    </Authenticator.Provider>
  );
};

export default App;
