/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "ruthereEventsApi",
            "endpoint": "https://ifrx4a38sb.execute-api.ca-central-1.amazonaws.com/uat",
            "region": "ca-central-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://n2j6gniokjfdxapeihiaqgic7i.appsync-api.ca-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "ca-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-c46fdkee7vcyhl7z6turiew46m",
    "aws_cognito_identity_pool_id": "ca-central-1:b03facc8-e7c0-4eb8-a77c-8524922a62df",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_PeEKT47wU",
    "aws_user_pools_web_client_id": "6qduj4oa8gsvel1uca6dug5und",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "ruthereconsolegood01038b46d6544865bbf24f5b9e73f180024-uat",
    "aws_user_files_s3_bucket_region": "ca-central-1"
};


export default awsmobile;
