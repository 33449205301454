import { FC } from "react";
import {
  DataGridPro,
  GridRowsProp,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid-pro";
import Paper from "@mui/material/Paper";
/**
 * @summary - Material-ui grid-data.
 * @param rows - props for setting the data
 * @param columns - props for setting the columns definitions
 *
 */

interface GridProps {
  rows: GridRowsProp;
  columns: GridColDef[];
}

const Grid: FC<GridProps> = ({ rows, columns }) => {
  const topBarHeight = 64;

  return (
    <Paper
      data-testid="grid"
      elevation={1}
      sx={{ height: `calc(100% - ${topBarHeight}px)` }}
    >
      <DataGridPro
        rows={rows}
        columns={columns}
        disableColumnSelector
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </Paper>
  );
};

export default Grid;
