

/**
 * @summary - Error page scene
 */

import { useIntl } from "react-intl";


const ErrorPage = () => {

  //TODO: Do something with the catched error.
  // const error = useRouteError();
  const intl = useIntl();

  return (
    <div>
      <h1>Oops!</h1>
      <p>{intl.formatMessage({id:"errorPageMessage", defaultMessage:"Une erreur est survenue."})}</p>
    </div>
  );
};

export default ErrorPage;
