import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import { IntlShape } from "react-intl";
import { Event, Report, ReportType } from "../../API";
import { getDatesFormat, getDocTitle, getReportType } from "./utils";
import("dayjs/locale/fr-ca");
import("dayjs/locale/en-ca");

/**
 * @summary - PDF file that will be generated for reports
 * @param events - events that occured within the report's timeline (from - to) that will be rendered in the pdf
 * @param report - the report that was clicked on
 */

interface IProps {
  events: Event[];
  report: Report;
  intl: IntlShape;
}

// Create styles
const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 40,
  },
  title: {
    fontSize: 24,
    marginBottom: 10,
  },
  dates: {
    fontSize: 18,
    marginBottom: 20,
  },
  userName: {
    fontSize: 16,
  },
  table: {
    display: "flex",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginBottom: 10,
  },
  tableColHeader: {
    fontSize: 14,
    backgroundColor: "#f0f0f0",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: "33.33%",
    padding: "3px",
  },
  tableCol: {
    fontSize: 12,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: "33.33%",
    padding: "3px",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
});

function groupBy<T>(arr: T[], fn: (item: T) => any) {
  return arr.reduce<Record<string, T[]>>((prev, curr) => {
    const groupKey = fn(curr);
    const group = prev[groupKey] || [];
    group.push(curr);
    return { ...prev, [groupKey]: group };
  }, {});
}

const ReportPDF = ({ report, events, intl }: IProps) => {
  const groupedEvents = groupBy(events, (e) => e.userName);
  const prefLang = localStorage.getItem("prefLang") ?? "fr";

  const docTitle = getDocTitle(report, intl);
  return (
    <Document title={docTitle}>
      <Page size="LETTER" style={styles.page}>
        <Image
          src="/cosior-logo.png"
          style={{
            width: 150,
            height: 80,
            borderRadius: "50%",
            // objectFit: "cover",
            objectFit: "contain",
            margin: "0 auto 10px auto",
          }}
        />
        <Text style={styles.title}>
          {intl.formatMessage({
            id: getReportType(report.type as ReportType, true),
            defaultMessage: "Rapport",
          })}
        </Text>
        <Text style={styles.dates}>{getDatesFormat(report)}</Text>

        {Object.entries(groupedEvents).map(([user, events]) => (
          <View key={user} style={styles.userName}>
            <Text style={styles.userName}>{user}</Text>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <Text style={styles.tableColHeader}>Date</Text>
                <Text style={styles.tableColHeader}>
                  {intl.formatMessage({
                    id: "workspaceSingle",
                    defaultMessage: "Poste de travail",
                  })}
                </Text>
                <Text style={styles.tableColHeader}>
                  {intl.formatMessage({
                    id: "eventSingle",
                    defaultMessage: "Événement",
                  })}
                </Text>
              </View>
              {events.map((item: Event) => (
                <View
                  key={`${item.clientID}-${item.createdAt}`}
                  style={styles.tableRow}
                >
                  <Text style={styles.tableCol}>
                    {dayjs(item.createdAt)
                      .locale(`${prefLang.substring(0, 2)}-ca`)
                      .format("LLL")}
                  </Text>
                  <Text style={styles.tableCol}>{item.deviceName}</Text>
                  <Text style={styles.tableCol}>{item.type}</Text>
                </View>
              ))}
            </View>
          </View>
        ))}
      </Page>
    </Document>
  );
};

export default ReportPDF;
