import { FC } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import LinkRouter from "../Router/LinkRouter";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import PeopleIcon from "@mui/icons-material/People";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EventIcon from "@mui/icons-material/Event";
import AssessmentIcon from '@mui/icons-material/Assessment';

import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material/SvgIcon";

/**
 * @summary - Link usign material's style and svg icons for the router.
 * @param text - string value for the link
 * @param to - string value for the redirection
 * @param nameIcon - string value for the svg icon
 *
 */

interface LinkMenuProps {
  text: string;
  to: string;
  nameIcon: string;
}

const LinkMenu: FC<LinkMenuProps> = ({ text, to, nameIcon }) => {
  const componentsMap: {
    [key: string]: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
      muiName: string;
    };
  } = {
    PeopleIcon,
    WorkspacesIcon,
    VisibilityIcon,
    EventIcon,
    AnnouncementIcon,
    AssessmentIcon,
  };

  const ComponentIcon = componentsMap[nameIcon];

  return (
    <ListItem disablePadding>
      <ListItemButton>
        <ListItemIcon>
          <ComponentIcon style={{ color: "#a4a6b3" }} />
        </ListItemIcon>
        <LinkRouter text={text} to={to} />
      </ListItemButton>
    </ListItem>
  );
};

export default LinkMenu;
