import React, { Dispatch, SetStateAction, useState } from "react";
import i18n from "../services/i18n";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import "dayjs/locale/fr-ca";
import "dayjs/locale/en-ca";
import { IntlProvider } from "react-intl";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import * as coreLocales from "@mui/material/locale";
import * as gridLocales from "@mui/x-data-grid/locales";
import * as pickerLocales from "@mui/x-date-pickers/locales";
import { Localization as GridLocalization } from "@mui/x-data-grid/utils/getGridLocalization";
import { frFR as customMUIFr } from "../locales/MUI/frFR";

export interface I18nProps {
  lang: string;
  setLang: Dispatch<SetStateAction<string>>;
}

/**
 * @summary - Context that store client's language. Also exposes methods to update laguage state.
 */

export const I18nContext = React.createContext<I18nProps | undefined>(
  undefined
);

export const useI18n = () => {
  const context = React.useContext(I18nContext);
  if (context === undefined) {
    throw new Error("useI18n must be used within a I18nProvider");
  }
  return context;
};

const I18nProvider = ({ children }: { children: React.ReactNode }) => {
  const [lang, setLang] = useState(
    localStorage.getItem("prefLang") ?? i18n.getDefaultLocale()
  ); //i18n.getDefaultLocale();

  const messages = i18n.getMessages(lang);

  //Key-value array for translating MUI Core components
  const langObjCore: { [key: string]: coreLocales.Localization } = {
    "fr-FR": coreLocales.frFR,
    "en-US": coreLocales.enUS,
  };

  //Key-value array for translating DataGrid component
  const langObjGrid: { [key: string]: GridLocalization } = {
    "fr-FR": customMUIFr,
    "en-US": gridLocales.enUS,
  };

  const langObjPickers: { [key: string]: any } = {
    "fr-FR": pickerLocales.frFR,
    "en-US": pickerLocales.enUS,
  };

  const theme = createTheme(
    langObjCore[lang],
    langObjGrid[lang],
    langObjPickers[lang]
  );

  return (
    <I18nContext.Provider
      value={{
        lang: lang,
        setLang: setLang,
      }}
    >
      <ThemeProvider theme={theme}>
        <IntlProvider
          messages={messages}
          locale={lang}
          key={lang}
          defaultLocale={i18n.getDefaultLocale()}
        >
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={`${lang.substring(0, 2)}-ca`}
          >
            {children}
          </LocalizationProvider>
        </IntlProvider>
      </ThemeProvider>
    </I18nContext.Provider>
  );
};

export default I18nProvider;
