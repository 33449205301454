import { API, GraphQLQuery, GraphQLSubscription } from "@aws-amplify/api";
import { useEffect, useState } from "react";
import {
  ListUsersQuery,
  ListUsersQueryVariables,
  OnCreateUserSubscription,
  OnCreateUserSubscriptionVariables,
  OnUpdateUserSubscription,
  OnUpdateUserSubscriptionVariables,
  User,
} from "../API";
import * as queries from "../graphql/queries";
import * as subscriptions from "../graphql/subscriptions";

const useUsers = (clientId: string) => {
  const [users, setUsers] = useState<User[]>([]);

  const fetchUsers = async () => {
    const variables: ListUsersQueryVariables = {
      clientID: clientId,
      limit: 1000,
    };
    try {
      const data = await API.graphql<GraphQLQuery<ListUsersQuery>>({
        query: queries.listUsers,
        variables,
      });

      const users = data.data?.listUsers?.items as User[];
      setUsers(users);
    } catch (error) {
      console.log("error fetching users >>", error);
    }
  };

  const subscribeUserUpdate = () => {
    const callback = (updatedUser: User) => {
      setUsers((prev) => {
        const updatedArray = prev.map((user) => {
          if (user.name === updatedUser.name) {
            return {
              ...updatedUser,
            };
          }
          return user;
        });
        return updatedArray;
      });
    };

    const variables: OnUpdateUserSubscriptionVariables = {
      filter: {
        clientID: {
          eq: clientId,
        },
      },
    };
    const subscription = API.graphql<
      GraphQLSubscription<OnUpdateUserSubscription>
    >({
      query: subscriptions.onUpdateUser,
      variables: variables,
    }).subscribe({
      next: ({ value }) => {
        callback(value.data?.onUpdateUser as User);
      },
      error: (error) => console.error(error),
    });

    return subscription;
  };

  const subscribeUserCreate = () => {
    const callback = (createdUser: User) => {
      //Important to use prev State, because users might be not fetched yet (async code in)
      setUsers((prev) => [...prev, { ...createdUser }]);
    };

    const variables: OnCreateUserSubscriptionVariables = {
      filter: {
        clientID: {
          eq: clientId,
        },
      },
    };

    const subscription = API.graphql<
      GraphQLSubscription<OnCreateUserSubscription>
    >({
      query: subscriptions.onCreateUser,
      variables: variables,
    }).subscribe({
      next: ({ value }) => callback(value.data?.onCreateUser as User),
      error: (error) => console.error(error),
    });

    return subscription;
  };

  useEffect(() => {
    fetchUsers();

    const subscriptionUsersUpdate = subscribeUserUpdate();
    const subscriptionUsersCreate = subscribeUserCreate();

    return () => {
      subscriptionUsersUpdate.unsubscribe();
      subscriptionUsersCreate.unsubscribe();
    };
  }, []);

  return {
    users: users,
    setUsers: setUsers,
  };
};

export default useUsers;
