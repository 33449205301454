import { GraphQLQuery, GraphQLResult } from "@aws-amplify/api";
import {
  Avatar,
  Box,
  Button,
  Container,
  Stack,
  TextField,
} from "@mui/material";
import { API } from "aws-amplify";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetUserQueryVariables,
  UpdateUserInput,
  UpdateUserMutation,
} from "../../API";
import Loading from "../../components/Loading/Loading";
import { useData } from "../../contexts/DataProvider";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { User } from "../../types";

/**
 * @summary - User Edit scene. As for right now (10 mars 2023), can only update user's name and description.
 * Also using REACT HOOK FORM LIBRARY : https://react-hook-form.com/
 */

type FormInputs = {
  name: string;
  description: string;
};

const UserEdit = () => {
  const { clientId } = useData();
  const navigate = useNavigate();
  const { id } = useParams();

  const intl = useIntl();

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isLoading, isDirty },
  } = useForm<FormInputs>({
    defaultValues: async () => await fetchUser(),
  });

  async function updateUser(formData: FormInputs) {
    const userDetails: UpdateUserInput = {
      clientID: clientId,
      name: formData.name,
      description: formData.description,
    };

    //TODO: Update the context users state to reflect new changes OR use subscribtion instead of query in the context
    await API.graphql<GraphQLQuery<UpdateUserMutation>>({
      query: mutations.updateUser,
      variables: { input: userDetails },
    });
  }

  /**
   * @summary - Fetch user by Id,
   * If Not Found, redirect NotFound,
   */
  async function fetchUser() {
    const variables: GetUserQueryVariables = {
      clientID: clientId,
      name: id!,
    };

    let apiData: GraphQLResult<GraphQLQuery<User>>;

    try {
      apiData = await API.graphql<GraphQLQuery<User>>({
        query: queries.getUser,
        variables: variables,
      });
    } catch (error) {
      console.error(`error fetching user`, error);
    }

    const { name, description } = apiData!.data?.getUser!;
    return { name: name || "", description: description || "" };
  }

  const onSubmit: SubmitHandler<any> = async (formData: FormInputs) => {
    try {
      await updateUser(formData);
      navigate(-1);
    } catch (_) {
      //do nothing because logged by error boundary
    }
  };

  if (isLoading) return <Loading />;

  return (
    <Container component="main" maxWidth="xs">
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar
          sx={{ m: 1, bgcolor: "primary.main", width: 70, height: 70 }}
        ></Avatar>

        <Controller
          control={control}
          name="name"
          // rules={{
          //   required: {
          //     value: true,
          //     message: "Un nom est requis",
          //   },
          // }}
          render={({ field }) => (
            <TextField
              {...field}
              // required
              InputProps={{
                readOnly: true,
              }}
              margin="normal"
              fullWidth
              label={intl.formatMessage({
                id: "userName",
                defaultMessage: "Nom",
              })}
              id="nom"
              error={!!errors.name}
              helperText={errors.name?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="description"
          // rules={{
          //   required: {
          //     value: true,
          //     message: "Une description est requise",
          //   },
          // }}
          render={({ field }) => (
            <TextField
              {...field}
              // required
              margin="normal"
              fullWidth
              label="Description"
              id="description"
              error={!!errors.description}
              helperText={errors.description?.message}
            />
          )}
        />

        <Stack
          mt={2}
          width="100%"
          direction="row"
          justifyContent="flex-end"
          spacing={1}
        >
          <Button
            type="button"
            variant="contained"
            onClick={() => navigate(-1)}
          >
            {intl.formatMessage({ id: "backButton", defaultMessage: "Retour" })}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="success"
            disabled={isSubmitting || !isDirty}
          >
            {intl.formatMessage({
              id: "editButton",
              defaultMessage: "Modifier",
            })}
          </Button>
        </Stack>
      </Box>
    </Container>
  );
};

export default UserEdit;
